import * as React from "react"
import Layout from "src/components/Layout"
import { Link } from "gatsby"
import { PageContext } from "src/utils/context";
import Button from "src/components/Button"

// markup
const NotFoundPage = () => {

  const location = { pathname: "/404/" };

  return (
    <PageContext.Provider
      value={{
        location: location
      }}
    >    
      <Layout location={{ pathname: "/404/" }}>
        <div class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-32 md:grid md:place-items-center lg:px-8">
          <div class="max-w-max mx-auto">
            <main class="sm:flex">
              <p class="text-4xl font-extrabold text-blue sm:text-5xl">404</p>
              <div class="sm:ml-6">
                <div class="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 class="text-4xl font-extrabold text-blue tracking-tight sm:text-5xl">Page not found</h1>
                  <p class="mt-1 text-base text-gray-500 prose max-w-prose">Sorry, we couldn't find what you were looking for. If you still can't find the content you need, please <Link className="text-teal" to="/contact-us/">contact us</Link>.</p>
                </div>
                <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Button to="/" className="!bg-blue hover:!bg-darkblue"> Go back home </Button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </Layout>
    </PageContext.Provider>
  )
}

export default NotFoundPage
